
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Racing+Sans+One&family=Roboto+Condensed:wght@400;700&family=Volkhov:ital@1&display=swap");
.image-gallery-content .image-gallery-slide .image-gallery-image{max-height: 535px!important;}
.container{ margin: 20px 0px;}
.image-gallery .image-gallery-using-mouse{border: 1px solid #ccc; padding: 20px;}
.filterArea{ border: 1px solid #ccc; padding: 20px;}
@import url('./App.css');
@import url('./Model.css');
@font-face {
    font-family:'font_896005' ;
    src:url("https://s3.amazonaws.com/configuretech/manufacturer/LloydMats/CLRB/media/font/RKISWFTE.TTF");
 }

 @font-face {
     font-family: 'font_896001';
     src: url("https://s3.amazonaws.com/configuretech/manufacturer/LloydMats/CLRB/media/font/RKASWFTE.TTF");
 }

 @font-face {
     font-family: 'font_896011';
     src: url("https://s3.amazonaws.com/configuretech/manufacturer/LloydMats/CLRB/media/font/CERSWFTE.TTF");
 }

 @font-face {
     font-family: 'font_896033';
     src: url("https://s3.amazonaws.com/configuretech/manufacturer/LloydMats/CLRB/media/font/BRTSWFTE.TTF");
 }
@font-face {
    font-family:'font_BSC';
    src: url('https://s3.amazonaws.com/configuretech/manufacturer/Averys/BGRG/media/font/BRTSWFTE.TTF');
}

 @font-face {
     font-family:'font_AVI';
     src: url("https://s3.amazonaws.com/configuretech/manufacturer/Averys/BGRG/media/font/AVANTEITALIC.TTF");
 }
@font-face {
    font-family:'font_EUR';
    src: url("./fonts/EUROTYPE.TTF");
}
