html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font: inherit;
    color: inherit;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    color: #337ab7;
    text-decoration: none;
}

a:focus,
a:hover {
    color: #23527c;
    text-decoration: underline;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    /* margin-top: 20px; */
    margin-bottom: 10px;
}

.h3,
h3 {
    font-size: 24px;
}

ol,
ul {
    margin-top: 0;
    margin-bottom: 10px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
}

@media (min-width: 768px) {
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
        float: left;
    }
    .col-sm-12 {
        width: 100%;
    }
    .col-sm-11 {
        width: 91.66666667%;
    }
    .col-sm-offset-1 {
        margin-left: 8.33333333%;
    }
}

.collapse {
    display: none;
}

.dropdown,
.dropup {
    position: relative;
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav>li {
    position: relative;
    display: block;
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav>li>a:focus,
.nav>li>a:hover {
    text-decoration: none;
    background-color: #eee;
}

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}

@media (min-width: 768px) {
    .navbar {
        border-radius: 4px;
    }
}

@media (min-width: 768px) {
    .navbar-header {
        float: left;
    }
}

.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
    .navbar-collapse {
        width: auto;
        border-top: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
    }
    .navbar-fixed-bottom .navbar-collapse,
    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse {
        padding-right: 0;
        padding-left: 0;
    }
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
    max-height: 340px;
}

.container-fluid>.navbar-collapse,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container>.navbar-header {
    margin-right: -15px;
    margin-left: -15px;
}

@media (min-width: 768px) {
    .container-fluid>.navbar-collapse,
    .container-fluid>.navbar-header,
    .container>.navbar-collapse,
    .container>.navbar-header {
        margin-right: 0;
        margin-left: 0;
    }
}

.navbar-fixed-bottom,
.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}

@media (min-width: 768px) {
    .navbar-fixed-bottom,
    .navbar-fixed-top {
        border-radius: 0;
    }
}

.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
}

.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

@media (min-width: 768px) {
    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: -15px;
    }
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

@media (min-width: 768px) {
    .navbar-toggle {
        display: none;
    }
}

.navbar-nav {
    margin: 7.5px -15px;
}

.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
}

@media (min-width: 768px) {
    .navbar-nav {
        float: left;
        margin: 0;
    }
    .navbar-nav>li {
        float: left;
    }
    .navbar-nav>li>a {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media (min-width: 768px) {
    .navbar-right {
        float: right !important;
        margin-right: -15px;
    }
}

.navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
    color: #777;
}

.navbar-default .navbar-nav>li>a {
    color: #777;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: #333;
    background-color: transparent;
}

.navbar-default .navbar-toggle {
    border-color: #ddd;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #e7e7e7;
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
    display: table;
    content: " ";
}

.btn-group-vertical>.btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
    clear: both;
}


/* end bootstrap portion */

html {
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: scrollbar;
}

body {
    background: #fff;
    font: 400 12px/1.8 "Open Sans", sans-serif;
    color: #666;
    -webkit-font-smoothing: antialiased;
}


/* Transition elsements */

a,
.btn {
    transition: all 0.125s ease-in-out 0s;
}

.navbar-custom+.main,
.navbar-demo+.main {
    margin-top: 50px;
}

.main {
    position: relative;
    background-color: #fff;
    z-index: 1;
}

.module,
.module-small {
    position: relative;
    padding: 40px 0 140px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

a {
    color: #111;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #aaa;
    outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.4;
    font-weight: 400;
}

p,
ol,
ul,
blockquote {
    margin: 0 0 20px;
}

.font-alt {
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.module-title {
    position: relative;
    letter-spacing: 4px;
    text-align: center;
    font-weight: 400;
    font-size: 30px;
    color: #111;
    margin: 0 0 70px;
}

#demo .module-title {
    margin: 0 !important;
    font-weight: bold;
    font-size: 16px;
}

.page-loader {
    position: fixed;
    background: #000;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    z-index: 9998;
}

.loader {
    position: absolute;
    border-left: 2px solid #ffffff;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    height: 46px;
    width: 46px;
    left: 50%;
    top: 50%;
    margin: -23px 0 0 -23px;
    text-indent: -9999em;
    font-size: 10px;
    z-index: 9999;
    -webkit-animation: load 0.8s infinite linear;
    -moz-animation: load 0.8s infinite linear;
    ms-animation: load 0.8s infinite linear;
    o-animation: load 0.8s infinite linear;
    animation: load 0.8s infinite linear;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 46px;
    height: 46px;
}

.navbar-demo {
    background-color: #000;
    color: #fff;
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 11px;
}

.navbar a {
    transition: color 0.125s ease-in-out 0s;
}

.navbar-custom .navbar-brand,
.navbar-demo .navbar-brand {
    font-family: "Racing Sans One", sans-serif;
    text-transform: none;
    letter-spacing: 4px;
    font-size: 24px;
    color: #fff;
}

.navbar-custom .nav li>a,
.navbar-demo .nav li>a {
    position: relative;
    color: rgba(255, 255, 255, 0.7);
}

.navbar-demo .nav>li>a:focus,
.navbar-demo .nav>li>a:hover,
.navbar-demo .nav .open>a,
.navbar-demo .nav .open>a:focus,
.navbar-demo .nav .open>a:hover,
.navbar-demo .dropdown-menu>li>a:focus,
.navbar-demo .dropdown-menu>li>a:hover {
    background: none;
    color: #fff;
}